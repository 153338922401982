<template>
    <div :style="{ height: screeHeight + 'px' }" style="background-color: rgb(239 239 239);">
        <div style="padding-top: 20px;">
            <van-cell title="用户名" :value="user.name" size="large" />
            <van-cell title="手机号" :value="user.mobile" size="large" />
            <!-- <van-cell title="证件类型" :value="user.zjlx" size="large" /> -->
            <van-cell title="证件号码" :value="user.idcardNumber" size="large" />
            <van-cell title="银行网点" :value="user.bankBranch" size="large" />
            <van-cell title="银行编码" :value="user.bankCode" size="large" />
            <van-cell title="银行卡号" :value="user.bankAccount" size="large" />
        </div>
    </div>
</template>
<script>
// import { user } from '../../data.js'
export default {
  name: 'info',
  data(){
        return {
            user:this.$store.state.user,
            screenWidth: document.documentElement.clientWidth,     // 屏幕宽
            screeHeight: document.documentElement.clientHeight,    // 屏幕高
        }
    },
    created(){
    },
    mounted(){
    },
    methods:{
    }

}
</script>
<style scoped>
.active{
    width: 70px;
    background-color: #fd1a34;
    color: white;
    text-align: center;
    border-radius: 15px;
    padding: 3px;
}
.nactive{
    width: 70px;
    padding: 3px;
    text-align: center;
    border-radius: 15px;
}
</style>